import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
// import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"
import { HubSpotFormComponent } from "../../components/hubspot-form-component"


const TwitterCampaignMessage = ({ location }) => {
  const title =
    "【当選通知のテンプレを公開】次につながるTwitterキャンペーンのメッセージとは？"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【当選通知のテンプレを公開】次につながるTwitterキャンペーンのメッセージとは？"
          pagedesc="Twitterキャンペーンで当選通知を送る際は次につながるようなメッセージを含めた内容にすることが重要です。そこで今回はPARKLoTで実際に使用しているテンプレをご紹介。文言の内容について、悩んでいる担当者の方はぜひ参考にしてみてください。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-campaign-message/twitter-campaign-message.png"
          pagepath="/blog/twitter-campaign-message"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              【当選通知テンプレを公開】次につながるTwitterキャンペーンのメッセージとは？
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年3月14日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="電車のホームでスマートフォンを持っている人"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-campaign-message/twitter-campaign-message.png"
                className="w-full"
              />
            </figure>
            <h2>
              Twitterインスタントウィン、当選メッセージも重要な「マーケティング」です
            </h2>
            <p>
              Twitterキャンペーンは企業認知を拡大する方法として、非常に効果的なマーケティング手法です。ただし、1回限りのキャンペーンで終わらせてしまったり、応募者へのアプローチを怠ったりしてしまうと、一時的な効果で終わってしまう可能性も。Twitterキャンペーンを行うのであれば、応募者に対して適切なアプローチを行い、次につなげることが重要です。
            </p>
            <h2>Twitterキャンペーンを起点に顧客化へつなげよう</h2>
            <p>
              そこで今回は、Twitterキャンペーンの当選通知ですぐに使える「メッセージのテンプレ」をご紹介します。
            </p>
            <p>
              <a
                href="https://www.park-lot.com/"
                target="_blank"
                rel="noreferrer"
              >
                PARKLoT（パークロット）
              </a>
              で実際に使用しているメッセージなので、当選通知の文言で悩んでいるご担当者の方は、ぜひ参考にしてみてください。ほかにも、TwitterキャンペーンのメリットやTwitterキャンペーンの運用に便利なサービスなどについても解説をしていきます。
            </p>
            <p>この記事でわかること</p>
            <ul className="blog__border-list">
              <li>なぜ当選通知などのメッセージが重要なのか</li>
              <li>どのようなメッセージを送るとよいのか</li>
              <li>当選通知のテンプレ（実際にPARKLoTで使用しているテンプレ）</li>
              <li>
                手間やコストを削減できる、Twitterキャンペーンを自動化する方法
              </li>
            </ul>
            <p>
              当選通知のメッセージ内容を知りたい方や、応募者への有効なアプローチ方法を学びたい方はぜひ最後までご覧ください。
            </p>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    改めて確認しておきたい！Twitterキャンペーンを行う5つのメリット
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    当選通知のテンプレを公開！応募者にとってほしい次のアクションを明確に
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    当選通知から「次につながる」Twitterキャンペーン
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    当選通知を手間なく・確実に行うなら「業務委託」PARKLoT
                    Liteは無料で利用OK！
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">
                改めて確認しておきたい！Twitterキャンペーンを行う5つのメリット
              </h2>
              <p>
                Twitterを利用したキャンペーンは非常に効果的なマーケティング手法の一つですが、なぜ有効といわれるのでしょうか。あらためてTwitterキャンペーンのメリットを確認しておきましょう。
              </p>
              <h3>1. 自社の商品・サービスの認知向上</h3>
              <p>
                拡散力の高いTwitterを利用し、プレゼント企画などのキャンペーンを行うことで、潜在的な顧客にアプローチできます。その結果、自社のブランドや自社で取り扱う商品やサービスの認知につながります。
              </p>
              <p>
                多くの商品やサービスが存在するなかで、自社について認知されるのは容易ではありません。しかし、拡散力のあるTwitterを利用することで、Twitterの利用者に認知される確率を高めることが可能になるのです。
              </p>
              <h3>2. 購買やサービス登録への誘導</h3>
              <p>
                自社を認知してもらった後は、自社商品の購入につなげたり、サービスを利用してもらったりすることが重要です。Twitterキャンペーンを利用すれば、自社サイトやECサイトへ誘導することが可能です。
              </p>
              <h3>3. フォロワー数の増加による企業イメージのアップ</h3>
              <p>
                キャンペーンを通じてフォロワーが増えると、企業イメージの向上につながります。なぜなら、フォロワー数の多い企業の方が、顧客側からすると信頼できるからです。そのため、フォロー解除されないように、継続してキャンペーンを行うなどの対策が必要です。
              </p>
              <h3>4. 経費を削減できる</h3>
              <p>
                インターネット上でキャンペーンを開催できるので、アナログな手法と比べて郵送などのコストを大幅に削減できます。
              </p>
              <h3>
                5.ユーザーのつぶやくきっかけ、双方向なやりとりのきっかけになる
              </h3>
              <p>
                キャンペーン開催は、ユーザーのつぶやきを促すきっかけにもなります。例えば、「応募しました！」「景品の〇〇、いつも愛用しているから当たると嬉しいな〜」など、フォロワーからのアクションが期待できます。そういった投稿や、キャンペーン後の当選報告に対して、企業アカウントから返答をするのもまた効果的です。
              </p>
              <br />
              <p>
                上記はTwitterキャンペーンを行うメリットの一部です。Twitterキャンペーンは、大規模なプロモーションに多額の予算を投下しなくても、自社の認知度を上げることに貢献します。中長期的には売上アップにもつながるので、ぜひ導入を検討してみてください。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id2">
                当選通知のテンプレを公開！応募者にとってほしい次のアクションを明確に
              </h2>
              <p>
                Twitterキャンペーンの主催者は、参加者に当選通知を行う必要があります。当選通知は単純に「当選・落選の結果を送ればいい」と思われがちですが、実はそうではありません。
              </p>
              <p>
                参加者・ユーザーに対して、「次につながる」アプローチを含めたメッセージを送ることが重要です。今後もキャンペーンを継続的に行うことなどを文面に盛り込むことで、今後につながるアプローチができます。商品が「ギフト券」の場合は、使い方も案内した方がいいでしょう。
              </p>
              <h3>●当選者への景品送付（DM）</h3>
              <div className="blog__border-highlight">
                <p>
                  ○○様
                  <br />
                  <br />
                  「〜〜キャンペーン」にご応募いただき、ありがとうございました。
                  <br />
                  抽選の結果、Amazonギフトカード〇〇円分が当選しました。
                  <br />
                  ご当選おめでとうございます！！
                  <br />
                  <br />
                  Amazonギフトカード○○円分のコードをお知らせします。
                  <br />
                  ※※あなたのコード※※
                  <br />
                  ----------------
                  <br />
                  AAAA-BBBB-CCCC
                  <br />
                  ----------------
                  <br />
                  Amazonギフトカードの使い方については、下記をご参照ください。
                  <br />
                  <a
                    href="https://www.amazon.co.jp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.amazon.co.jp
                  </a>
                  <br />
                  この度はPARKLoTのキャンペーンにご参加いただきありがとうございました！
                  <br />
                  <strong>
                    今後も定期的にキャンペーンを行いますので、ぜひご参加くださいね。
                  </strong>
                  <br />
                  開催中の他のキャンペーンはこちらからチェック！
                  <br />
                  ▶︎
                  <a
                    href="https://goodluck.park-lot.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://goodluck.park-lot.com/
                  </a>
                </p>
              </div>
              <br />
              <p>
                また、周知拡大したいWebサイトへ誘導するのも、応募者を引き込む手段の一つ。より自社を知ってもらうきっかけにもなります。当選結果の文面はできるだけコンパクトにして、ひと目で当たりかどうか分かるようにしましょう。
              </p>
              <h3>●結果通知（結果通知ページ）</h3>
              <p>・当たり</p>
              <img
                placeholder="tracedSVG"
                alt="当たりの結果通知ページ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-campaign-message/twitter-campaign-message-2.png"
                className="w-full"
              />
              <p>
                キャンペーン終了後、5営業日以内にDMでご連絡します。フォローしたままお待ちくださいね♪
              </p>
              <p>他にも開催中のプレゼントキャンペーンはここからチェック</p>
              <span className="emoji">&#x1F449;</span>
              <a
                href="https://goodluck.park-lot.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://goodluck.park-lot.com/
              </a>
              <br /> <br />
              <p>・はずれ</p>
              <img
                placeholder="tracedSVG"
                alt="はずれの結果通知ページ"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/twitter-campaign-message/twitter-campaign-message-3.png"
                className="w-full"
              />
              <p>
                PARKLoTでは、今後もAmazonギフトカードが当たるキャンペーンを開催します！Twitterをチェックして、キャンペーンに応募してくださいね！
              </p>
              <p>他にも開催中のプレゼントキャンペーンはここからチェック</p>
              <span className="emoji">&#x1F449;</span>
              <a
                href="https://goodluck.park-lot.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://goodluck.park-lot.com/
              </a>
              <br />
              <br />
              <p>
                PARKLoTで使用しているテンプレを公開しましたが、自社で行うキャンペーンの内容によってメッセージの内容も異なります。上記のテンプレを参考にして、自社に合ったメッセージを検討してみてください。
              </p>
            </div>
            <div>
              <h2 id="id3">当選通知から「次につながる」Twitterキャンペーン</h2>
              <p>
                当選通知のメッセージについて説明しましたが、効果的なTwitterキャンペーンについてもあらためて考えてみましょう。当選通知の文面を変えるよりも、Twitterキャンペーンの全体方針を見直す方が、パフォーマンスアップの観点では優先度が高いからです。
              </p>
              <h3>Twitterキャンペーンは継続してこそ意味がある</h3>
              <p>
                まず、キャンペーンを「単発」で開催するのはあまり効果がありません。なぜなら、十分に認知拡大できないことに加え、参加者がキャンペーン終了後にフォロー解除してしまうリスクがあるからです。
              </p>
              <p>
                キャンペーンを数回に分けて継続することで、自社のキャンペーンの認知度の向上や、フォローの継続といった効果が期待できます。その結果、自社の認知拡大や企業イメージの向上につながります。フォロワーが継続的にキャンペーンに参加することで、商品・サービスへの愛着も高まっていきます。
              </p>
              <h3>実は大変なTwitterキャンペーンの継続</h3>
              <p>
                Twitterキャンペーンのおおまかな流れは「応募→抽選→結果通知→景品の発送」が一般的です。しかし、ツールなどを利用せず自社で行う場合、応募者と主催者にとっていくつかのデメリットがあります。
              </p>
              <br />
              <p>【応募者】</p>
              <ul>
                <li>結果が分かるまでタイムラグがある</li>
                <li>必要事項を入力するなど手間がかかり、面倒と感じてしまう</li>
              </ul>
              <br />
              <p>【主催者】</p>
              <ul>
                <li>時間・手間・コストがかかる</li>
                <li>連絡を失念するなど、ミスコミュニケーションにつながる</li>
              </ul>
              <br />
              <p>
                また、最初のキャンペーン後に「効果がイマイチ」「コストもかかる」と感じて、次回のキャンペーン開催が困難になる可能性もあります。
              </p>
              <h3>これらを解決するインスタントウィン</h3>
              <p>
                上記のデメリットを解決するために、多くの企業で「インスタントウィン」というシステムが活用されています。
              </p>
              <p>
                インスタントウィンとは、キャンペーン応募の段階で当選・落選の結果が分かるシステムのこと。フォローやリツイートを行った後、ツイートに記載されたリンクをクリックするだけなので、応募者の手間が少なく拡散力も高まります。
              </p>
              <p>
                その結果、企業側は効果的にフォロワーの獲得や認知拡大につなげられます。自動化されたシステムを利用するので、担当者の負担がなくなり工数を大幅に削減することができます。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id4">
                当選通知を手間なく・確実に行うなら「業務委託」PARKLoT
                Liteは無料で利用OK！
              </h2>
              <h3>無料で使える！PARKLoTが手掛けるサービスとは</h3>
              <p>
                <a
                  href="https://www.park-lot.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  PARKLoT（パークロット）
                </a>
                では、顧客獲得に有効な「インスタントウィン」を無料でお試しできるサービス「PARKLoT
                Lite（パークロットライト）」を提供しています。
              </p>
              <p>
                応募者が結果をすぐに確認できるのが、PARKLoT
                Liteでインスタントウィンをする最大のメリット。1アカウント最大3回のキャンペーンまで、無料で本サービスを利用できます。継続的にキャンペーンを行えるので、自社の認知拡大や商品・サービスのPR、発信力の強化につなげることが可能です。
              </p>
              <h3>PARKLoT Liteの思い</h3>
              <p>
                Twitterキャンペーンにインスタントウィンを導入する場合、「どれくらいの費用対効果なのか」と気になる担当者様も多いのではないでしょうか。実際のところ、他社のケースではそれなりの導入コストが必要です。
              </p>
              <p>
                しかし、PARKLoTでは「多くの企業にビジネスチャンスを広げて欲しい」という思いから、無料でインスタントウィンを体験できる『PARKLoT
                Lite』を開発・提供しています。
              </p>
              <h3>『PARKLoT Lite』の概要</h3>
              <ul>
                <li>
                  開催タイプ…インスタントウィン（URLから結果確認のみ、DM通知なし）
                </li>
                <li>景品数…1種類のみ（1回のキャンペーンにつき）</li>
                <li>開催回数…3回まで無料（その後1万円/月で継続利用可能）</li>
                <li>その他…開催前の審査が必須</li>
                <li>
                  PARKLoT利用規約ページ…
                  <a
                    href="https://www.park-lot.com/agreement"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.park-lot.com/agreement
                  </a>
                </li>
                <li>
                  PARKLoTプライバシーポリシーページ…
                  <a
                    href="https://www.park-lot.com/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.park-lot.com/privacy
                  </a>
                </li>
              </ul>
              <br />
              <p>
                Twitterキャンペーンを利用して「ビジネスを拡大したい！」「認知度を高めたい」と思ったら、まずはお試しいただくのが一番です。Twitterキャンペーンをどのように運用していけばいいのかお困りの方も、
                <a
                  href="https://hub.park-lot.com/free-consulting"
                  target="_blank"
                  rel="noreferrer"
                >
                  無料相談ページ
                </a>
                より、お気軽にご連絡をいただければと思います。
              </p>
            </div>
            <br />
            <HubSpotFormComponent />
            <br />
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            {/* <div className="case_study main">
              <CvBtn />
            </div> */}
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのフォロワーが少ない状態でのSNS運用は効果が薄い？プレゼントキャンペーンを実施して一気にフォロワーを獲得しましょう！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-get-followers-enterprise"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【今すぐ実践！】Twitter企業アカウントでフォロワーを増やす方法
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default TwitterCampaignMessage
